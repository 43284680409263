import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useState} from "react";
import {useDispatch} from "react-redux";
import {clearCart} from "../../redux_toolkit/slices/cartSlice";
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import Checkbox from "@mui/joy/Checkbox";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

export default function PopupOrder({order, isOpen, setOpen}) {
    const [userName, setUserName] = useState('')
    const [phone, setPhone] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const utm_campaign = searchParams.get('utm_campaing');
    const hasUtmCampaign = utm_campaign !== null ? `?utm_campaing=${utm_campaign}` : ``;

    const sendOrder = () => {
        const orderWithUserData = {
            name: userName,
            phone: phone,
            order: order
        }

        fetch(`https://yellowshop.in.ua/cart-order.php${hasUtmCampaign}`,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(orderWithUserData)
        }).finally(()=>{
            ReactGA.event({
                category: 'Lead',
                action: `Make order for product ${order[0].id}`
            });
            TagManager.dataLayer({
                dataLayer: {
                    event: "lead",
                    category: "Ecommerce",
                    action: `Make order for product ${order[0].id}`,
                    label: "Lead",
                },
            });
            navigate('/order')
            window.scrollTo(0, 0)
        })
        dispatch(clearCart())
    }
    return (
        <div>
            <Dialog open={isOpen} onClose={()=>setOpen(false)}>
                <DialogTitle>Останній етап</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Заповніть цю анкету і ми вам перетелефонуємо!
                    </DialogContentText>
                    <TextField
                        autoFocus
                        value={userName}
                        onChange={e => setUserName(e.target.value)}
                        margin="dense"
                        id="name"
                        label="Ім'я"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                        margin="dense"
                        id="name"
                        label="Номер телефону"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                    <Checkbox checked
                              label="Погоджуюся з умовами оферти"
                              size="sm"
                              sx={{
                                  "& .JoyCheckbox-checkbox":{
                                      backgroundColor: '#ececec',
                                      border: "1px solid black"
                                  },
                                  mt: '20px',
                              }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setOpen(false)}>Редагувати замовлення</Button>
                    <Button onClick={sendOrder}>Завершити замовлення</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}