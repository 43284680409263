import React from 'react';
import {Paper} from "@mui/material";
import DescriptionProduct from "./DescriptionProduct";
import NameProduct from "./NameProduct";
import Theses from "./Theses";

import BuyButton from "../../../../components/buttons/BuyButton";
import {useDispatch} from "react-redux";
import {addProduct} from "../../../../redux_toolkit/slices/cartSlice";
import PriceProduct from "./PriceProduct";
import CallMadeIcon from '@mui/icons-material/CallMade';
import Typography from "@mui/material/Typography";
import ReactGA from "react-ga4";
import NewPostLogo from "../../../../assets/images/logos/novapost-logo.webp";
import UkrPostLogo from "../../../../assets/images/logos/ukrpost-logo.webp";

function AboutProduct({buyRef, valueInSelect, product}) {
    const {name, description, theses, price} = product
    const dispatch = useDispatch()
    const scrollToBuy = () => {
        buyRef.current.scrollIntoView()
        buyRef.current.click()
    }

    return (
        <Paper sx={{width: '100%', height: '100%',borderRadius: '0px'}}>
            <div
                style={{
                    backgroundColor: "#f39f86",
                    backgroundImage: 'linear-gradient(315deg, rgb(38 58 108) 0%, rgb(78 89 124) 74%)',
                    margin: '0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '0px',
                }}>
                <NameProduct name={name}/>
            </div>

            <DescriptionProduct description={description}/>
            <Theses theses={theses}/>

            <Typography fontSize={24}
                        align="center"
                sx={{
                    fontWeight: 'medium'
                }}
              >ЗАМОВЛЯЙТЕ ЗАРАЗ</Typography>
            <Typography fontSize={16}
                        align="center"
                        sx={{
                            mb:2,
                            fontWeight: 'medium'
                        }}
            >З ДОСТАВКОЮ</Typography>

            <div style={{display: 'flex', flexDirection: 'row', overflow: 'hidden', justifyContent: 'center'}}>
                <img
                    src={UkrPostLogo}
                    style={{ maxWidth: '40%', height: '40px'}}
                    alt="Укр пошта"
                />
                <Typography fontSize={16}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontWeight: 'medium',
                                marginLeft: '10px',
                            }}
                >АБО</Typography>
                <img
                    src={NewPostLogo}
                    style={{marginLeft: '10px', maxWidth: '40%', height: '40px'}}
                    alt="Нова пошта"
                />
            </div>

            <Typography
                align="center"
                variant={'h6'}
                sx={{
                    mt: 2,
                    fontWeight: 'medium'
                        }}
            >СПЛАЧУЙТЕ БЕЗПЕЧНО ПІСЛЯ</Typography>

            <Typography fontSize={14}
                        sx={{
                            mb:2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: 'medium'
                        }}
            >ОГЛЯДУ НА ПОШТІ</Typography>


            <PriceProduct price={price} hasSale={product.hasSale}/>

            <div style={{margin: '10px',display: 'flex', justifyContent: 'center', gap: '10px'}}>
                <BuyButton icon={<CallMadeIcon/>}
                           sx={{p: '10px',width:'100%'}}
                           text={"Замовити зараз"} onClick={scrollToBuy}
                           variant={"contained"}
                />
                <BuyButton sx={{p: '10px',width:'100%'}}
                           text={"У кошик"}
                           onClick={() => {
                               ReactGA.event({
                                   category: 'Add product to cart',
                                   action: `Add product ${product.productId} to cart`
                               });
                               dispatch(addProduct({...product, characteristic: valueInSelect}))
                           }}
                           variant={"contained"}
                />
            </div>
        </Paper>
    );
}

export default AboutProduct;