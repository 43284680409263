import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import { BrowserRouter } from "react-router-dom"
import { PersistGate } from 'redux-persist/integration/react';

import store, {persistor} from '../src/redux_toolkit/store'

import './assets/fonts/BebasNeuePro-Bold.woff';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-PQJFSHVJ'
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <BrowserRouter>
                  <App />
              </BrowserRouter>
          </PersistGate>
      </Provider>
  </React.StrictMode>
);

reportWebVitals();
